/*!
 * Start Bootstrap - Creative v4.0.0-beta.2 (https://startbootstrap.com/template-overviews/creative)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-creative/blob/master/LICENSE)
 */

$primary : #4a90e2;
@import "mainNav";


body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif; }

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: $primary; }

hr.light {
  border-color: #fff; }

a {
  color: $primary;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s; }
a:hover {
  color: $primary; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }

.bg-primary {
  background-color: $primary !important; }

.bg-dark {
  background-color: #212529 !important; }

.text-faded {
  color: rgba(255, 255, 255, 0.7); }

section {
  padding: 8rem 0; }

.section-heading {
  margin-top: 0; }

::-moz-selection {
  color: #fff;
  background: #212529;
  text-shadow: none; }

::selection {
  color: #fff;
  background: #212529;
  text-shadow: none; }

img::selection {
  color: #fff;
  background: transparent; }

img::-moz-selection {
  color: #fff;
  background: transparent; }

body {
  -webkit-tap-highlight-color: #212529; }





header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);
  background-image: url("../img/header.jpg");
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
header.masthead hr {
  margin-top: 30px;
  margin-bottom: 30px; }
header.masthead h1 {
  font-size: 2rem; }
header.masthead p {
  font-weight: 300; }
@media (min-width: 768px) {
  header.masthead p {
    font-size: 1.15rem; } }
@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    max-height: 1000px;
    min-height: 650px;
    padding-top: 0;
    padding-bottom: 0; }
  header.masthead h1 {
    font-size: 3rem; } }
@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 4rem; } }

.service-box {
  max-width: 400px; }

.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto; }
.portfolio-box .portfolio-box-caption {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: rgba(lighten($primary, 5%), 0.9) ;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s; }
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center; }
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  padding: 0 15px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase; }
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-size: 18px; }
.portfolio-box:hover .portfolio-box-caption {
  opacity: 1; }
.portfolio-box:focus {
  outline: none; }
@media (min-width: 768px) {
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 16px; }
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 22px; } }

.text-primary {
  color: #4a90e2 !important; }

.btn {
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 0px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }

.btn-xl {
  padding: 1rem 2rem; }

.btn-primary {
  background-color: #4a90e2;
  border-color: $primary; }
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #fff;
  background-color: darken($primary, 5%) !important; }
.btn-primary:active, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem lighten($primary, 5%) !important; }




.bmd-form-group .custom-file-control, .bmd-form-group , .bmd-form-group input::placeholder, .bmd-form-group label {
  line-height: 1;
}

.custom-file-control, .is-focused .custom-file-control, .is-focused {
  background-image: linear-gradient(0deg,#009688 2px,rgba(0,150,136,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0) !important;
}
.custom-file-control {
  background: no-repeat bottom,50% calc(100% - 1px);
  background-size: 0 100%,100% 100%;
  border: 0;
  transition: background 0s ease-out;
  padding-left: 0;
  padding-right: 0;
}



.custom-file-control:focus,
.bmd-form-group.is-focused,
.bmd-form-group.is-focused .custom-file-control {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
outline: none!important;
box-shadow: none;

}
