$primary : #4a90e2;


#mainNav {
  border-bottom: 1px solid rgba(33, 37, 41, 0.1);
  background-color: #fff;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  transition: transform 0.2s;
  will-change: transform;
  font-size: 16px;
  padding: 0rem;

}

.modal-open #mainNav{
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
 }



#mainNav .nav-link{
  font-size: .9rem;
}

#mainNav .navbar-brand{
  line-height: 30px;
}



.pinned {
  transform: translateY(0%)!important;
}

.unpinned{
  transform: translateY(-100%);
  box-shadow: none;
}


#mainNav .navbar-brand {
  font-weight: 700;
  text-transform: uppercase;
  color: $primary;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif; }
#mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: $primary; }
#mainNav .navbar-nav > li.nav-item > a.nav-link,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #212529; }
#mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
  color: $primary; }
#mainNav .navbar-nav > li.nav-item > a.nav-link.active,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active {
  color: $primary !important;
  background-color: transparent; }
#mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active:hover {
  background-color: transparent; }
@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: transparent; }
  #mainNav .navbar-brand {
    color: rgba(255, 255, 255, 0.7); }
  #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
    color: #fff; }
  #mainNav .navbar-nav > li.nav-item > a.nav-link {
    padding: 0.5rem 1rem; }
  #mainNav .navbar-nav > li.nav-item > a.nav-link,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
    color: rgba(255, 255, 255, 0.7); }
  #mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
    color: #fff; }
  #mainNav.navbar-shrink {
    //border-bottom: 1px solid rgba(33, 37, 41, 0.1);
    box-shadow: 0 1px 0 0 #ccc;
    background-color: #fff; }
  #mainNav.navbar-shrink .navbar-brand {
    color: $primary; }
  #mainNav.navbar-shrink .navbar-brand:focus, #mainNav.navbar-shrink .navbar-brand:hover {
    color: $primary; }
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link,
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus {
    color: #212529; }
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:hover,
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus:hover {
    color: $primary; } }


/*footer*/



@media(min-width: 769px) {
  .page-footer{
    margin-top: 10rem
  }

};



.page-footer{
  background-color: #2c3e50;
  color: #fff;
  font-family: "Lato", 'Open Sans', Arial, sans-serif !important;
}

.page-footer .btn{
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 0px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
}

.page-footer .btn-primary {
  background-color: #4a90e2;
  border-color: #4a90e2;
}

.footer-links {
  padding-left: 0px;
  list-style-type: none;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-weight: 100;
  will-change: font-weight;
}

.footer-links > li > a:hover {
  font-weight: 700;
}

.columns-2 {
  -webkit-column-count: 2;
  column-count: 2;
}



